import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../components/logo"
import LogoInsta from "../components/logoInsta"

import "./index.css"

const IndexPage = () => (
  <Layout>
    <SEO title="B.Cluster" />
    <div style={{ width: 234, margin: "2rem auto" }}>
      <Logo />
    </div>
    <h1>
      Conseil stratégique • Création • Communication interne • Communication de
      projet • Stratégie 360°
    </h1>
    <h2>
      Graphisme // Print // Vidéo // Motion Design // Sites Web // Animation //
      Evénements
    </h2>

    <div style={{ margin: 20, textAlign: "center" }}>
      <a href="https://www.instagram.com/agence_b.cluster/">
        Retrouvez-nous sur Instagram
        <LogoInsta />
      </a>
    </div>
  </Layout>
)

export default IndexPage
